<mat-toolbar class="app-toolbar" matRipple>
	<div class="container" fxLayoutAlign="space-between">
		<div fxLayoutAlign="center">
			<button class="transparent-button" (click)="navigateToPs4()">
				<mat-icon class="white-arrow">keyboard_arrow_left</mat-icon>
				<img src="../assets/logo-ps4-v2.png" class="app-logo" />
			</button>

			<div routerLink="/programari-online" class="app-title font">
				<span>DIRECȚIA GENERALĂ DE EVIDENȚĂ A PERSOANELOR SECTOR 4</span>
			</div>
		</div>
	</div>
</mat-toolbar>
<mat-toolbar class="app-breadcrumbs">
	<div class="container" fxLayoutAlign="start">
		<button class="button breadcrumbs-back-btn font" *ngIf="isBreadcrumbRoute" [routerLink]="'/programari-online'">
			INAPOI
		</button>
		<button mat-button [routerLink]="'/programari-online'" [routerLinkActive]="'active-link'" [fxHide.lt-md]="isBreadcrumbRoute">
			<!-- <mat-icon>event_calendar</mat-icon> -->
			<span class="home-page-btn font">Programări online</span>
		</button>
		<button mat-icon-button [routerLink]="'/programari-online'" [routerLinkActive]="'active-link'" fxHide.gt-sm *ngIf="isBreadcrumbRoute">
			<mat-icon>chevron_left</mat-icon>
		</button>
		<span class="app-breadcrumb-separator" *ngIf="isBreadcrumbRoute" fxHide.lt-md>
			/
		</span>
		<button mat-button *ngIf="isBreadcrumbRoute" [routerLink]="currentUrl" [routerLinkActive]="'active-link'">
			<span>{{ routeTitles[currentUrl] }}</span>
		</button>
	</div>
</mat-toolbar>
<div class="container router-outlet-container">
	<router-outlet></router-outlet>
</div>