import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FlexLayoutModule } from '@angular/flex-layout';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatStepperModule } from '@angular/material/stepper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { MatRippleModule } from '@angular/material/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		FlexLayoutModule,
		MatToolbarModule,
		MatMenuModule,
		MatIconModule,
		MatSidenavModule,
		MatListModule,
		MatButtonModule,
		MatStepperModule,
		MatFormFieldModule,
		MatRadioModule,
		MatSelectModule,
		MatCheckboxModule,
		MatInputModule,
		MatDatepickerModule,
		MatExpansionModule,
		MatSnackBarModule,
		MatProgressSpinnerModule,
		MatDialogModule,
		MatAutocompleteModule,
		MatTooltipModule,
		MatDividerModule,
		MatCardModule,
		MatRippleModule,
		MatProgressBarModule,
		MatDialogModule
	],
	exports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		FlexLayoutModule,
		MatToolbarModule,
		MatMenuModule,
		MatIconModule,
		MatSidenavModule,
		MatListModule,
		MatButtonModule,
		MatStepperModule,
		MatFormFieldModule,
		MatRadioModule,
		MatSelectModule,
		MatCheckboxModule,
		MatInputModule,
		MatDatepickerModule,
		MatExpansionModule,
		MatSnackBarModule,
		MatProgressSpinnerModule,
		MatDialogModule,
		MatAutocompleteModule,
		MatTooltipModule,
		MatDividerModule,
		MatCardModule,
		MatRippleModule,
		MatProgressBarModule,
		MatDialogModule,
		MatMomentDateModule
	],
	providers: [
		MatDatepickerModule,
		MatMomentDateModule
	]
})
export class SharedModule { }
