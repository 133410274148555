import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
	{
		path: 'act-de-identitate',
		loadChildren: () =>
			import('./act-de-identitate-form/act-de-identitate-form.module').then(
				(m) => m.ActDeIdentitateFormModule
			),
	},

	{
		path: 'casatorie',
		loadChildren: () =>
			import('./casatorie-form/casatorie-form.module').then(
				(m) => m.CasatorieFormModule
			),
	},
	{
		path: 'transcriere',
		loadChildren: () =>
			import('./transcriere-form/transcriere-form.module').then(
				(m) => m.TranscriereFormModule
			),
	},
	{
		path: 'documente',
		loadChildren: () =>
			import('./documents/documents.module').then((m) => m.DocumentsModule),
	},
	{
		path: 'suport-online',
		loadChildren: () =>
			import('./suport-online/suport-online.module').then(
				(m) => m.SuportOnlineModule
			),
	},
	{
		path: 'plati-online',
		loadChildren: () =>
			import('./plati-online/plati-online.module').then(
				(m) => m.PlatiOnlineModule
			),
	},
	{
		path: 'programari-online',
		loadChildren: () =>
			import('./programari-online/programari-online.module').then(
				(m) => m.ProgramariOnlineModule
			),
	},

	{
		path: '',
		redirectTo: 'programari-online',
		pathMatch: 'full',
	},
	{
		path: '**',
		redirectTo: 'programari-online',
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {
		scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
        relativeLinkResolution: 'legacy',
		useHash: true
	})],
	exports: [RouterModule],
})
export class AppRoutingModule { }
